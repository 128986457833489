import React from "react";
import Dashboard from "../screens/Dashboard";

import ProductScreen from "../screens/ProductScreen";
import ProfileCardsPage from "../screens/ProfileCardsPage";
import ProfileCategoryScreen from "../screens/ProfileCategoryScreen";
import ProfileSubCategoryScreen from "../screens/ProfileSubCategoryScreen";
import FormScreen from "../screens/FormScreen";
import {
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import PagesScreen from "../screens/PagesScreen";
import ProfileScreen from "../screens/ProfileScreen";

import EditProfileScreen from "../screens/EditProfileScreen";
import ContactUsScreen from "../screens/ContactUsScreen";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Pressable, Text } from "react-native";
import OrderHistoryScreen from "../screens/OrderHistoryScreen";
import ReviewScreen from "../screens/ReviewScreen";
import SubmitReviewScreen from "../screens/SubmitReviewScreen";
import ChooseSubInputScreen from "../screens/ChooseSubInputScreen";
import ChooseSubSelectionScreen from "../screens/ChooseSubSelectionScreen";
import ChooseFileScreen from "../screens/ChooseFileScreen";
import CheckoutScreen from "../screens/CheckoutScreen";
import OrderCompleteScreen from "../screens/OrderCompleteScreen";
import PaymentFallback from "../screens/PaymentFallback";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
import CustomText from "../components/CustomText";
import PromoCodeScreen from "../screens/PromoCodeScreen";
import LoginScreen from "../screens/LoginScreen";
import MiniLoginScreen from "../screens/MiniLoginScreen";
import { connect } from "react-redux";
import SelectUsername from "../screens/SelectUsername";
import ProfileView from "../screens/ProfileView";

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const ProfileStackNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Profile" component={ProfileScreen} />
      <Stack.Screen name="OrderHistory" component={OrderHistoryScreen} />
      <Stack.Screen name="ContactUs" component={ContactUsScreen} />
      <Stack.Screen name="EditProfile" component={EditProfileScreen} />
    </Stack.Navigator>
  );
};

const BottomTabNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          backgroundColor: "#FDEBE8",
          borderTopWidth: 0,
          paddingTop: 8,
          paddingBottom: 8,
          height: 65,
        },
        tabBarActiveTintColor: "#CF4258",
        tabBarInactiveTintColor: "#FCBC98",
      }}
      // backBehavior="history"
    >
      <Tab.Screen
        name="Dashboard"
        component={Dashboard}
        options={{
          tabBarIcon: ({ size, color }) => (
            <Ionicons color={color} size={size} name="md-home" />
          ),
          title: "Home",
        }}
      />
      <Tab.Screen
        name="Profiles"
        component={ProfileStackNavigator}
        options={{
          tabBarIcon: ({ size, color }) => (
            <MaterialCommunityIcons name="account" color={color} size={size} />
          ),
        }}
      />
    </Tab.Navigator>
  );
};

const LoggedInNavigator = ({ isLoggedIn, isNameRequired }) => {
  return (
    <Stack.Navigator
      screenOptions={({ navigation }) => ({
        headerTitle: "",
        headerStyle: {
          elevation: 0, // Remove shadow on Android
          shadowOpacity: 0, // Remove shadow on iOS
          borderBottomWidth: 0, // Remove bottom border
        },
        headerLeft: () => {
          // You might need to implement your custom logic here
          const goBack = navigation.canGoBack();
          return (
            <Pressable
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                if (goBack) {
                  navigation.goBack();
                } else {
                  navigation.replace("Homeroutes", {
                    screen: "Dashboard",
                  });
                }
              }}
            >
              <AntDesign
                name="arrowleft"
                size={24}
                color="black"
                style={{ marginHorizontal: 15 }}
              />
              <CustomText style={{ fontSize: 16, fontWeight: 600 }}>
                Back
              </CustomText>
            </Pressable>
          );
        },
      })}
      backBehavior="history"
      // screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="Homeroutes"
        component={BottomTabNavigator}
        options={({ route }) => {
          return {
            headerShown:
              !!getFocusedRouteNameFromRoute(route) &&
              getFocusedRouteNameFromRoute(route) !== "Dashboard",
          };
        }}
      />
      {(!isLoggedIn || isNameRequired) && (
        <Stack.Screen
          name="Login"
          options={{ headerShown: false }}
          component={LoginScreen}
        />
      )}
      {(!isLoggedIn || isNameRequired) && (
        <Stack.Screen
          name="ProfileShort"
          options={{ headerShown: false }}
          component={MiniLoginScreen}
        />
      )}
      <Stack.Screen name="ProfileCards" component={ProfileCardsPage} />
      <Stack.Screen name="Fallback" component={PaymentFallback} />
      <Stack.Screen name="Product" component={ProductScreen} />
      <Stack.Screen name="ProfileCategory" component={ProfileCategoryScreen} />
      <Stack.Screen name="Pages" component={PagesScreen} />
      <Stack.Screen name="ProfileView" component={ProfileView} />
      <Stack.Screen name="Create" component={FormScreen} />
      <Stack.Screen name="Review" component={ReviewScreen} />
      <Stack.Screen name="PreCheckout" component={SelectUsername} />
      <Stack.Screen name="Checkout" component={CheckoutScreen} />
      <Stack.Screen name="OrderComplete" component={OrderCompleteScreen} />
      <Stack.Screen name="SubmitReview" component={SubmitReviewScreen} />
      <Stack.Screen
        name="ChoosePromo"
        component={PromoCodeScreen}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ChooseSubInput"
        component={ChooseSubInputScreen}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ChooseFile"
        component={ChooseFileScreen}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ChooseSubSelection"
        component={ChooseSubSelectionScreen}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ProfileSubCategory"
        component={ProfileSubCategoryScreen}
      />
    </Stack.Navigator>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  isNameRequired: state.user.isNameRequired,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInNavigator);
