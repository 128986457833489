import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { connect } from "react-redux";
import { Entypo, FontAwesome, MaterialIcons } from "@expo/vector-icons";
import ListView from "../components/ListView";
import CustomText from "../components/CustomText";

const ContactUsScreen = ({ config }) => {
  const data = [
    {
      subTitle: "Contact Mobile",
      title: config["app.config.contact.mobile"],
    },
    {
      subTitle: "Contact Email",
      title: config["app.config.contact.email"],
    },
    {
      subTitle: "Contact Address",
      title: config["app.config.contact.address"],
    },
  ];

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Contact Us</CustomText>
      </View>
      <View style={styles.container}>
        <CustomText
          style={[styles.mainHeading, { color: "#000", fontSize: 24 }]}
        >
          {config["app.config.contact.name"]}
        </CustomText>
        <View style={styles.divider}></View>
        {/* <ListView data={data} uniqueKey="title" hideSearchBar /> */}
        {data.map((item) => (
          <View
            style={{
              alignItems: "flex-start",
              rowGap: 12,
              width: "100%",
              marginBottom: 20,
            }}
          >
            <CustomText style={{ fontWeight: 600, fontSize: 20 }}>
              {item.subTitle}
            </CustomText>
            <CustomText style={{ fontSize: 14 }}>{item.title}</CustomText>
          </View>
        ))}
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  config: state?.cms?.config,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    rowGap: 4,
    backgroundColor: "#F8F8F8",
    padding: 16,
  },
  divider: {
    width: "100%",
    marginVertical: 20,
    height: StyleSheet.hairlineWidth * 2,
    backgroundColor: "#DB5E71",
  },
  upperContainer: {
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F8F8F8",
    paddingBottom: 20,
  },
  mainHeading: {
    color: "#32505C",
    fontSize: 24,
    fontWeight: 400,
  },
});
