import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Pressable,
  Image,
  Platform,
} from "react-native";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import CustomText from "./CustomText";

const ProfileList = ({ data }) => {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <CustomText style={styles.heading}>Categories</CustomText>
      <FlatList
        data={data}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={(itemData) => {
          console.log(itemData);
          return (
            <Pressable
              style={styles.categoryContainer}
              onPress={() => {
                navigation.navigate("ProfileSubCategory", {
                  id: itemData.item.profile_category_id,
                });
              }}
            >
              <View
                style={{
                  padding: 8,
                  backgroundColor: "white",
                  borderRadius: 12,
                }}
              >
                <Image
                  source={{ uri: itemData.item.image_url }}
                  style={[
                    {
                      resizeMode: "contain",
                      width: 72,
                      height: 72,
                    },
                  ]}
                />
              </View>
              <CustomText style={{ fontWeight: 400, fontSize: 12 }}>
                {itemData.item.title}
              </CustomText>
            </Pressable>
          );
        }}
        keyExtractor={(item) => {
          return item.profile_category_id;
        }}
        alwaysBounceVertical={false}
      />
    </View>
  );
};

export default ProfileList;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    rowGap: 12,
    marginBottom: 16,
  },
  categoryContainer: {
    paddingHorizontal: 12,
    rowGap: 8,
    alignItems: "center",
  },
  heading: { color: "#455A64", fontWeight: 400, fontSize: 20 },
});
