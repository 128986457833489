import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Pressable,
  Image,
  Platform,
} from "react-native";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { AntDesign, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { connect, useDispatch } from "react-redux";
import { getTemplateData, getTemplatePreData } from "../store/actions/cms";
import shareMessage from "../utils/shareMessage";
import { generateShareContent } from "../utils/generateMessage";
import { notificationActions } from "../store/slices/notification";
import CustomText from "./CustomText";
import * as Linking from "expo-linking";
import WebView from "../components/WebView";
import { API_BASE_URL } from "../config";

const OrderList = ({
  data,
  title,
  token,

  getTemplateData,
  setSelectedOrder,
  setSelectedOrderDetail,
  setModalVisible,
  setModalShareVisible,
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  return (
    <View style={styles.container}>
      <View style={styles.headingContainer}>
        <CustomText style={styles.heading}>{title}</CustomText>
        <Pressable
          onPress={() =>
            navigation.navigate("Homeroutes", {
              screen: "Profiles",
              params: {
                screen: "OrderHistory",
              },
            })
          }
        >
          <CustomText
            style={{ color: "#455A64", fontWeight: 300, fontSize: 16 }}
          >
            View more
          </CustomText>
        </Pressable>
      </View>
      <FlatList
        data={data}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={(itemData) => {
          const { item: order } = itemData;
          return (
            <View style={{ marginRight: 16 }}>
              <Pressable
                style={styles.middleContainer}
                onPress={() => {
                  navigation.navigate("Pages", {
                    isOrderDetail: 1,
                    id: order?.items[0]?.username,
                  });
                }}
              >
                {/* IMage container */}
                <View
                  style={{
                    backgroundColor: "#F9C6A4",
                    padding: 8,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 12,
                    position: "relative",
                  }}
                >
                  <WebView
                    uri={`${API_BASE_URL}api-public/html_viewer/${order?.items[0]?.product.template_html_id}?template_data_id=${order?.items[0]?.template_data_id}`}
                    iStyle={{
                      borderWidth: 0,
                      width: 110,
                      height: 145,
                      borderRadius: 12,
                      overflow: "hidden",
                    }}
                    scrolling="no"
                  />
                  {/* <Image
                    source={{ uri: order.items[0]?.product?.image?.url }}
                    style={[
                      {
                        resizeMode: "contain",
                        width: 120,
                        height: 192,
                        borderRadius: 26,
                      },
                    ]}
                  /> */}
                </View>

                <View
                  style={{
                    rowGap: 10,
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                    flex: 1,
                  }}
                >
                  <View
                    style={{
                      height: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Pressable
                      onPress={async () => {
                        setSelectedOrderDetail(order);
                        setModalShareVisible(true);
                      }}
                    >
                      <Ionicons
                        name="share-social-sharp"
                        size={28}
                        color="#DB5E71"
                      />
                    </Pressable>
                    <Pressable
                      onPress={async () => {
                        navigation.navigate("Create", {
                          edit: order.items[0]?.template_data_id,
                        });
                      }}
                    >
                      <Ionicons name="pencil-sharp" size={20} color="#DB5E71" />
                    </Pressable>
                    <Pressable
                      onPress={async () => {
                        setSelectedOrder(order?.items[0]?.username);
                        setSelectedOrderDetail(order);
                        setModalVisible(true);
                      }}
                    >
                      <AntDesign name="arrowdown" size={20} color="#DB5E71" />
                    </Pressable>
                  </View>
                </View>
              </Pressable>
            </View>
          );
        }}
        keyExtractor={(item) => {
          return item.profile_id;
        }}
        alwaysBounceVertical={false}
        ListFooterComponent={
          <View
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pressable
              onPress={() => navigation.navigate("ProfileCards")}
              style={{
                borderRadius: 16,
                alignItems: "center",
                justifyContent: "center",
                padding: 20,
                backgroundColor: "#FCA29B",
              }}
            >
              <AntDesign name="plus" size={24} color="black" />
            </Pressable>
          </View>
        }
      />
    </View>
  );
};

const mapStateToProps = (state) => ({
  config: state?.cms?.config,

  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplateData: (token, id) => dispatch(getTemplateData(token, id)),

  setModalVisible: (data) =>
    dispatch(notificationActions.setModalVisible(data)),
  setSelectedOrder: (data) =>
    dispatch(notificationActions.setSelectedOrder(data)),
  setSelectedOrderDetail: (data) =>
    dispatch(notificationActions.setSelectedOrderDetail(data)),
  setModalShareVisible: (data) =>
    dispatch(notificationActions.setModalShareVisible(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    rowGap: 12,
  },
  categoryContainer: {
    padding: 12,
    rowGap: 12,
    alignItems: "center",
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: { color: "#455A64", fontWeight: 400, fontSize: 20 },
  middleContainer: {
    flexDirection: "row",
    padding: 12,
    backgroundColor: "white",
    borderRadius: 20,
    columnGap: 4,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 16,
  },
});
