import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  Dimensions,
  Pressable,
} from "react-native";
import React, { useState } from "react";
import CustomText from "./CustomText";

const IntroSlider = ({ handleLastScreen }) => {
  const [imgActive, setImageActive] = useState(0);
  const WindowWidth = Dimensions.get("window").width;
  const height = Dimensions.get("window").height;

  const image = [
    { id: "1", source: require("../assets/Intro-1.svg") },
    { id: "2", source: require("../assets/Intro-2.svg") },
    { id: "3", source: require("../assets/Intro-3.svg") },
  ];

  const onchange = (nativeEvent) => {
    if (nativeEvent) {
      const slide = Math.ceil(
        nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width
      );
      if (slide != imgActive) {
        setImageActive(slide);
      }
    }
  };
  return (
    <View style={styles.Container}>
      <ScrollView
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onScroll={({ nativeEvent }) => onchange(nativeEvent)}
      >
        {image.map((val, ind) => (
          <View
            key={ind}
            style={{ backgroundColor: "#f8f8f8", height: "100%" }}
          >
            <Image
              source={val.source}
              style={{
                width: WindowWidth > 480 ? 480 : WindowWidth,
                height: height - 50,
              }}
              resizeMode="cover"
            />
          </View>
        ))}
      </ScrollView>
      <View style={styles.wrapDot}>
        {image.map((val, ind) => (
          <View
            key={ind}
            style={imgActive == ind ? styles.dotActive : styles.dot}
          ></View>
        ))}
      </View>
      {imgActive === image.length - 1 && (
        <View style={{ paddingHorizontal: 10, zIndex: 10 }}>
          <Pressable
            style={{
              backgroundColor: "#DB5E71",
              flexDirection: "row",
              flex: 1,
              marginVertical: 4,
              padding: 10,
              justifyContent: "center",
              columnGap: 2,
              alignItems: "center",
              borderRadius: 8,
            }}
            onPress={() => {
              handleLastScreen();
            }}
          >
            <CustomText
              style={{
                color: "white",
                fontSize: 12,
              }}
            >
              Next
            </CustomText>
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default IntroSlider;

const styles = StyleSheet.create({
  Container: {
    flex: 1,
  },
  wrapDot: {
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 20,
    position: "absolute",
    zIndex: 1,
    bottom: 20,
  },

  dotActive: {
    margin: 3,
    backgroundColor: "#DB5E71",
    width: 50,
    height: 10,
    borderRadius: 20,
  },
  dot: {
    margin: 3,
    backgroundColor: "#DB5E71",
    width: 20,
    height: 10,
    borderRadius: 20,
  },
});
