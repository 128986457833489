import {
  FlatList,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React from "react";
import { useSelector } from "react-redux";
import CustomText from "./CustomText";

const Footer = () => {
  const dashboardData = useSelector((state) => state.cms.dashboard);
  return (
    <>
      <View style={styles.middleContainer}>
        <CustomText style={styles.secondaryHeading}>All Caught Up!</CustomText>
      </View>
      {dashboardData?.cms && (
        <View style={{ paddingHorizontal: 16 }}>
          <CustomText style={[styles.secondaryHeading, { paddingBottom: 16 }]}>
            Easy as 1, 2 & 3!
          </CustomText>
          <FlatList
            data={dashboardData?.cms?.filter(
              (item) => item.entity_id === "cms.dashboard.steps"
            )}
            horizontal
            showsHorizontalScrollIndicator={false}
            renderItem={(itemData) => {
              return (
                <Image
                  source={{ uri: itemData.item.data?.url }}
                  style={[
                    {
                      resizeMode: "contain",
                      width: 240,
                      height: 147,
                      borderRadius: 24,
                      marginRight: 16,
                    },
                  ]}
                />
              );
            }}
            keyExtractor={(item) => {
              return item.profile_id;
            }}
            alwaysBounceVertical={false}
          />
        </View>
      )}
      {dashboardData?.cms &&
        dashboardData?.cms.find(
          (item) => item.entity_id === "cms.dashboard.footerBanner"
        ) && (
          <Pressable
            onPress={() =>
              shareMessage(
                `${config["app.cms.text.share_text"]} ${config["app.cms.url.play_store"]}`
              )
            }
          >
            <Image
              source={{
                uri: dashboardData?.cms.find(
                  (item) => item.entity_id === "cms.dashboard.footerBanner"
                )?.data?.url,
              }}
              style={{
                width: "100%",
                aspectRatio: 1 / 2,
                resizeMode: "contain",
              }}
            />
          </Pressable>
        )}
    </>
  );
};

export default Footer;

const styles = StyleSheet.create({
  middleContainer: {
    alignItems: "center",
    padding: 16,
    gap: 16,
  },
  secondaryHeading: {
    color: "#252525",
    fontSize: 16,
    fontWeight: "bold",
  },
});
