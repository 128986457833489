import React, { useEffect, useState } from "react";
import { View, Text, Animated, StyleSheet, Platform } from "react-native";
import { useDispatch } from "react-redux";
import { notificationActions } from "../store/slices/notification";
import CustomText from "./CustomText";

const TopNotification = ({ message }) => {
  const dispatch = useDispatch();
  const [animation] = useState(new Animated.Value(-100));

  useEffect(() => {
    const showNotification = Animated.timing(animation, {
      toValue: 0,
      duration: 500, // Adjust as needed
      useNativeDriver: true,
    });

    // const hideNotification = Animated.timing(animation, {
    //   toValue: -100,
    //   duration: 500, // Adjust as needed
    //   useNativeDriver: true,
    // });

    // Show notification
    showNotification.start();

    // Hide the notification after a certain duration
    const hideTimeout = setTimeout(() => {
      // hideNotification.start();
      dispatch(notificationActions.toggleError());
    }, 3000); // 3 seconds, adjust as needed

    return () => {
      clearTimeout(hideTimeout);
      dispatch(notificationActions.toggleError());
    };
  }, []);

  const translateYStyle = {
    transform: [{ translateY: animation }],
  };

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.notification, translateYStyle]}>
        <CustomText style={styles.text}>{message}</CustomText>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    width: "100%",
    zIndex: 9999, // Ensure it's on top of other content
  },
  notification: {
    backgroundColor: "#DB5E71", // Customize your notification styles here
    padding: 10,
  },
  text: {
    color: "white",
    textAlign: "center",
  },
});

export default TopNotification;
