import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import WebView from "../components/WebView";
import ListView from "../components/ListView";
import { profileActions } from "../store/slices/profile";

const ProfileSubCategoryScreen = ({
  categoryData,
  route,
  navigation,
  templateId,
}) => {
  const dispatch = useDispatch();
  const { id } = route.params;

  const handleSubCategorySelection = (subId) => {
    if (subId) {
      const catData = categoryData.find(
        (cat) => cat.profile_category_id === id
      );
      const title = catData?.sub_category.find(
        (item) => item.profile_sub_category_id === subId
      ).title;
      if (title) {
        dispatch(profileActions.setSubCategoryId({ id: subId, title }));
        dispatch(profileActions.setCategoryId({ id, title: catData.title }));
      }
      if (templateId) {
        navigation.navigate("Create");
      } else {
        navigation.navigate("ProfileCards");
      }
    }
  };
  return (
    <ListView
      data={
        categoryData.find((cat) => cat.profile_category_id === id)?.sub_category
      }
      title="What profile type are you creating?"
      hideImage
      uniqueKey="profile_sub_category_id"
      onPress={handleSubCategorySelection}
    />
  );
};

const mapStateToProps = (state) => ({
  categoryData: state?.cms?.lookup?.profile_category,
  templateId: state?.profile?.templateId,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSubCategoryScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  upperContainer: {
    flex: 5,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 20,
    fontWeight: "bold",
  },
  lowerContainer: {
    padding: 16,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "#DB5E71",
    width: "100%",
    paddingVertical: 6,
    paddingHorizontal: 20,
    marginTop: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },
});
