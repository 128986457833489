import { Pressable, StyleSheet, Text, View, Platform } from "react-native";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, setName, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { notificationActions } from "../store/slices/notification";
import CustomText from "../components/CustomText";

const MiniLoginScreen = ({ profileData, token }) => {
  const [userData, setUserData] = useState({
    first_name: profileData?.first_name,
  });
  const dispatch = useDispatch();
  return (
    <View style={styles.container}>
      <View style={{ rowGap: 10, marginBottom: "10%" }}>
        <CustomText style={styles.mainHeading}>
          What should we call you?
        </CustomText>
        <CustomText style={styles.secondaryHeading}>
          This name will be used to display on your profiles
        </CustomText>
      </View>
      <CustomText style={styles.phoneHeading}>Name</CustomText>
      <View style={styles.numberContainer}>
        <Input
          placeholder="Name"
          defaultValue={profileData?.first_name}
          styleProp={{
            // borderColor: "#f09aab",
            // borderWidth: 1,
            padding: 12,
            borderRadius: 12,
          }}
          onChangeText={(e) => {
            setUserData((prev) => ({
              ...prev,
              first_name: e,
            }));
          }}
        />
      </View>
      <Pressable
        style={styles.button}
        onPress={async () => {
          dispatch(notificationActions.toggleError());
          if (userData.first_name && userData.first_name.length !== 0) {
            await dispatch(setName(token, { ...userData }));
          } else {
            dispatch(notificationActions.toggleError("Invalid Name"));
          }
        }}
      >
        <CustomText style={styles.buttonText}>Done</CustomText>
      </Pressable>
    </View>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.user.profileData,
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MiniLoginScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 80,
    rowGap: 20,
  },

  numberContainer: {
    flexDirection: "row",
    width: "100%",
    gap: 28,
    justifyContent: "space-between",
  },
  upperContainer: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 16,
  },
  mainHeading: {
    color: "#DB5E71",
    fontSize: 28,
    fontWeight: "bold",
  },
  middleContainer: {
    borderRadius: 20,
    alignItems: "flex-start",
    backgroundColor: "#DB5E71",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 16,
  },
  button: {
    backgroundColor: "#DB5E71",
    width: "90%",
    paddingVertical: 20,
    borderRadius: 20,
    paddingHorizontal: 20,
    position: "fixed",
    bottom: 80,
    maxWidth: 440,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
});
