import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import ListView from "../components/ListView";

const ProfileCategoryScreen = ({
  categoryData,
  navigation,
  templateProfileCategory,
}) => {
  const dispatch = useDispatch();

  const handleCategorySelection = (id) => {
    if (id) {
      navigation.navigate("ProfileSubCategory", {
        id: id,
      });
    }
  };
  return (
    <ListView
      data={categoryData.filter((item) => {
        if (templateProfileCategory && templateProfileCategory.length > 0) {
          return templateProfileCategory.includes(item.profile_category_id);
        } else return true;
      })}
      title="Categories"
      uniqueKey="profile_category_id"
      onPress={handleCategorySelection}
    />
  );
};

const mapStateToProps = (state) => ({
  categoryData: state?.cms?.lookup?.profile_category,
  templateProfileCategory: state?.profile?.templateProfileCategory ?? [],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileCategoryScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  upperContainer: {
    flex: 5,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 20,
    fontWeight: "bold",
  },
  lowerContainer: {
    padding: 16,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "#DB5E71",
    width: "100%",
    paddingVertical: 6,
    paddingHorizontal: 20,
    marginTop: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },
});
