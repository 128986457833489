import {
  View,
  Text,
  StyleSheet,
  Platform,
  ActivityIndicator,
  Pressable,
} from "react-native";
import React, { useEffect, useRef } from "react";
import { WebView as Wv } from "react-native-webview";
import { useState } from "react";

const WebView = ({ uri, iStyle = {}, scrollAllowed }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    console.log("loaded");
    setLoading(false);
  };

  if (Platform.OS === "web") {
    return (
      <View style={iStyle}>
        {loading && (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              ...iStyle,
            }}
          >
            <ActivityIndicator size="large" color="#407ce4" />
          </View>
        )}

        <iframe
          src={uri}
          style={{
            flex: 1,
            borderWidth: 0,
            pointerEvents: scrollAllowed ? "auto" : "none",
            display: loading ? "none" : "block",
            ...iStyle,
          }}
          onLoad={handleLoad}
        />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <Wv
        source={{
          uri,
        }}
        style={{ flex: 1, ...iStyle }}
      />
    </View>
  );
};

export default WebView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
