import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { AntDesign, Ionicons } from "@expo/vector-icons";

const ImagePlaceHolder = ({ uri, onPress, onDelete }) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      {uri ? (
        <Image source={{ uri }} style={{ width: "100%", aspectRatio: 1 / 1 }} />
      ) : (
        <AntDesign name="camera" size={24} />
      )}
      {uri && (
        <Pressable
          style={{ position: "absolute", top: 4, right: 4 }}
          onPress={onDelete}
        >
          <Ionicons name="ios-close-sharp" size={16} color="#DB5E71" />
        </Pressable>
      )}
    </Pressable>
  );
};

export default ImagePlaceHolder;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    aspectRatio: 1 / 1,
    backgroundColor: "#a2a2a2",
    borderRadius: 8,
    margin: 6,
    minWidth: 100,
    position: "relative",
  },
  heading: {
    fontSize: 18,
  },
});
