import React, { useState } from "react";
import {
  Button,
  Modal,
  View,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  StyleSheet,
  Pressable,
} from "react-native";

const BackdropModal = ({ visible, onClose, Content }) => {
  const closeModal = () => {
    onClose(); // Callback to handle modal closure in parent component if needed
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={closeModal}
    >
      <Pressable style={styles.modalBackground} onPress={onClose}>
        <TouchableWithoutFeedback style={styles.container}>
          <View style={styles.modalContainer}>{Content}</View>
        </TouchableWithoutFeedback>
      </Pressable>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background color
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    width: "90%",
    maxWidth: 440,
    elevation: 5, // For Android shadow
  },
});

export default BackdropModal;
