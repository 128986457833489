import { Ionicons, Octicons } from "@expo/vector-icons";
import React, { useRef, useState } from "react";
import AutocompleteInput from "react-native-autocomplete-input";
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  Platform,
  TouchableOpacity,
  Pressable,
} from "react-native";
import CustomText from "./CustomText";
import { useDispatch, useSelector } from "react-redux";
import { checkUsername } from "../store/actions/cms";

export default function Input({
  label,
  boxHeight = 42,
  onFocus = () => {},
  styleProp = {},
  deleteButton,
  suggest,
  itemKey,
  Icon,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionData, setSuggestionData] = useState([]);

  const timerRef = useRef(null);
  const validatorRef = useRef(false);

  const dispatch = useDispatch();
  const token = useSelector((state) => state?.user?.token);
  const { ["app.template.share_url.v2"]: shareUrl } = useSelector(
    (state) => state?.cms?.config
  );

  const handleInputChange = async (text) => {
    if (text.length > 3) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      validatorRef.current = false;
      timerRef.current = setTimeout(async () => {
        const data = await dispatch(checkUsername(token, text, 1, 1));
        setShowSuggestions(true);
        setSuggestionData(data.suggestions);
        timerRef.current = null;
      }, 300);
    } else setShowSuggestions(false);
  };

  const handleNumericInputChange = async (text) => {
    const numericValue = text.replace(/[^0-9]/g, "");
    props.onChangeText(numericValue);
  };

  if (suggest) {
    return (
      <AutocompleteInput
        data={suggestionData}
        hideResults={!showSuggestions}
        renderTextInput={() => (
          <View
            style={[
              styles.inputContainer,
              {
                borderColor: isFocused ? "#7975b5" : "#f3f4fb",
                height: boxHeight,
                position: "relative",
                borderRadius: 12,
                minHeight: boxHeight,
              },
            ]}
          >
            {suggest && (
              <Octicons
                name="mention"
                size={16}
                style={{ position: "absolute", left: 12 }}
                color="black"
              />
            )}
            <TextInput
              {...props}
              onChangeText={(e) => {
                handleInputChange(e);
                props.onChangeText(e);
              }}
              style={[
                styles.input,
                {
                  height: boxHeight,
                  minHeight: boxHeight,
                  borderColor: "transparent",
                },
                styleProp,
                { paddingLeft: suggest ? 36 : 0 },
              ]}
              placeholderTextColor="#858490"
              autoCorrect={false}
              onFocus={() => {
                onFocus();
                setIsFocused(true);
              }}
              onBlur={() => {
                setIsFocused(false);
                setTimeout(() => setShowSuggestions(false), 500);
                if (!validatorRef.current) {
                  props.onChangeText("");
                  props.onChangeText("", `${itemKey}_url`);
                }
              }}
            />
            {!deleteButton && (
              <TouchableOpacity
                style={{ position: "absolute", right: 12 }}
                // onPress={deleteButton}
              >
                <Ionicons name="ios-close-sharp" size={16} color="black" />
              </TouchableOpacity>
            )}
          </View>
        )}
        // hideResults
        inputContainerStyle={{
          width: "100%",
          flex: 1,
          // backgroundColor: "black",
          borderRadius: 8,
          borderColor: "transparent",
          // overflow: "hidden",
          height: boxHeight,
          minHeight: boxHeight,
          borderRadius: 12,
          ...Platform.select({
            web: { boxShadow: "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px" },
            android: { elevation: 10 },
            ios: {
              shadowColor: "rgba(0, 0, 0, 0.16)",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 1,
              shadowRadius: 4,
            },
          }),
        }}
        flatListProps={{
          renderItem: (item, index) => (
            <TouchableOpacity
              onPress={() => {
                validatorRef.current = true;
                props.onChangeText(item.item.username);
                props.onChangeText(
                  `${shareUrl}${item.item.username}`,
                  `${itemKey}_url`
                );
              }}
              style={{ padding: 12, zIndex: 50, backgroundColor: "white" }}
            >
              <CustomText
                style={{
                  fontWeight: 400,
                  // color: "#858490",
                }}
              >
                {item.item.username}
              </CustomText>
            </TouchableOpacity>
          ),
        }}
        containerStyle={{
          borderRadius: 12,
          borderWidth: 0,
        }}
        listContainerStyle={{
          height:
            suggestionData.length == 0
              ? 0
              : suggestionData.length < 2
              ? 50
              : 100,
          overflow: "scroll",
          position: "relative",
        }}
      />
    );
  }
  return (
    <View
      style={{
        width: "100%",
        flex: 1,
        // backgroundColor: "black",
        borderRadius: 8,
        // overflow: "hidden",
        height: boxHeight,
        minHeight: boxHeight,
        borderRadius: 12,
        ...Platform.select({
          web: { boxShadow: "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px" },
          android: { elevation: 10 },
          ios: {
            shadowColor: "rgba(0, 0, 0, 0.16)",
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 1,
            shadowRadius: 4,
          },
        }),
      }}
    >
      {/* {label && <CustomText style={styles.label}>{label}</CustomText>} */}
      <View
        style={[
          styles.inputContainer,
          {
            borderColor: isFocused ? "#7975b5" : "#f3f4fb",
            height: boxHeight,
            position: "relative",
            borderRadius: 12,
            minHeight: boxHeight,
          },
        ]}
      >
        {!!Icon && Icon}
        {/* <Icon type="font-awesome" name={iconName} style={styles.icon} /> */}
        <TextInput
          {...props}
          onChangeText={(e) => {
            if (props.numericInput) handleNumericInputChange(e);
            else props.onChangeText(e);
          }}
          style={[
            styles.input,
            {
              height: boxHeight,
              minHeight: boxHeight,
            },
            styleProp,
            { paddingLeft: !!Icon ? 36 : 12 },
          ]}
          placeholderTextColor="#858490"
          autoCorrect={false}
          onFocus={() => {
            onFocus();
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
        {deleteButton && (
          <TouchableOpacity
            style={{ position: "absolute", right: 12 }}
            onPress={deleteButton}
          >
            <Ionicons name="ios-close-sharp" size={16} color="black" />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    marginVertical: 5,
    color: "#fff",
    fontSize: 16,

    fontWeight: "bold",
  },
  inputContainer: {
    backgroundColor: "#fff",
    flexDirection: "row",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    alignItems: "center",
  },
  input: {
    color: "#000",
    fontWeight: 400,
    width: "100%",
    paddingHorizontal: 8,
  },
  error: {
    color: "red",
    fontSize: 12,
    marginTop: 7,
  },
});
