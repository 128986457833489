import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
  FlatList,
  ActivityIndicator,
} from "react-native";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getReviews } from "../store/actions/cms";
import CustomText from "../components/CustomText";
import { loginRequired } from "../utils/loginRequired";

const ReviewScreen = ({
  reviews,
  route,
  navigation,
  token,
  loading,
  getData,
}) => {
  const { id } = route.params;

  useEffect(() => {
    if (token) getData(token, id);
    else loginRequired(navigation);
  }, [token]);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }
  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Queries</CustomText>
      </View>
      <View style={styles.container}>
        {reviews?.length === 0 ? (
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <CustomText style={styles.mainHeading}>No reviews yet</CustomText>
          </View>
        ) : (
          <View style={{ flex: 1, backgroundColor: "#fafafa" }}>
            <FlatList
              data={reviews}
              showsVerticalScrollIndicator={false}
              renderItem={(itemData) => {
                return (
                  <Pressable style={styles.categoryContainer}>
                    <CustomText
                      style={[styles.secondaryHeading, { marginBottom: 4 }]}
                    >
                      {itemData.item.created_at}
                    </CustomText>

                    <CustomText style={styles.primaryHeading}>
                      Query: {itemData.item.review}
                    </CustomText>
                    <CustomText style={styles.primaryHeading}>
                      Rating: {itemData.item.rating}
                    </CustomText>
                    <CustomText style={styles.secondaryHeading}>
                      By: {itemData.item.username}
                    </CustomText>
                    <CustomText style={styles.secondaryHeading}>
                      contact: {itemData.item.mobile}
                    </CustomText>
                  </Pressable>
                );
              }}
              keyExtractor={(item) => {
                return item.order_rating_review_id;
              }}
              alwaysBounceVertical={false}
            />
          </View>
        )}
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  reviews: state?.cms?.reviews,
  token: state?.user?.token,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token, id) => dispatch(getReviews(token, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
  },
  upperContainer: {
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#32505C",
    fontSize: 24,
    fontWeight: 400,
  },
  primaryHeading: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#DB5E71",
  },
  secondaryHeading: {
    color: "#a5a5a5",
    fontWeight: "bold",
    fontSize: 16,
  },
  categoryContainer: {
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    paddingVertical: 6,
    columnGap: 4,
    borderRadius: 20,
    alignItems: "flex-start",
    margin: 16,
    marginTop: 0,
    ...Platform.select({
      web: {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
});
