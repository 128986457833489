import {
  ActivityIndicator,
  Image,
  Platform,
  StyleSheet,
  Linking,
  View,
} from "react-native";
import React, { Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import * as Progress from "react-native-progress";
import TopNotification from "../components/Notification";
import AppLoading from "expo-app-loading";
import { getProfile } from "../store/actions/auth";
import { useState } from "react";
import * as Font from "expo-font";
import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getConfig, getLookup } from "../store/actions/cms";
import { NavigationContainer } from "@react-navigation/native";
// import * as Linking from "expo-linking";
// const NotLoggedInNavigator = React.lazy(() =>
//   import("../routes/NotLoggedInNavigator")
// );

// const LoggedInNavigator = React.lazy(() =>
//   import("../routes/LoggedInNavigator")
// );
import NotLoggedInNavigator from "../routes/NotLoggedInNavigator";
import LoggedInNavigator from "../routes/LoggedInNavigator";
import IntroSlider from "../components/IntroSlider";
import OpeningModals from "../components/OpeningModals";

const CoreScreen = ({ notification, config }) => {
  const linking = {
    prefixes: [
      "https://web-view-native.onrender.com",
      "http://localhost:19006",
    ],
    config: {
      screens: {
        Homeroutes: {
          screens: {
            Dashboard: "dashboard",
            Profiles: {
              screens: {
                Profile: "profile",
                ContactUs: "contact-us",
                OrderHistory: "order-history",
              },
            },
          },
        },
        Login: "login",
        ProfileShort: "profile-short",
        Product: "product/:id",
        EditProfile: "profile/edit",
        ProfileCards: "profile-cards",
        ProfileCategory: "profile-category",
        // ProfileView: "profile-view/:id",
        ProfileSubCategory: "profile-category/:id/sub-category",
        Create: "create",
        Fallback: "payment/fallback",
        Review: "order/:id/review",
        SubmitReview: "profile-view/:id",
        Pages: "pages/:id",
        OrderComplete: "order-complete",
        Checkout: "order-checkout/:templateId",
        PreCheckout: "order-pre-checkout/:templateId",
        ChoosePromo: "order-promo",
        ChooseSubInput: "choose-sub-input/:key",
        ChooseSubSelection: "choose-sub-selection/:key",
        ChooseFile: "choose-file/:key",
      },
    },
  };
  const [isTryingLogin, setIsTryingLogin] = useState(true);
  const [introPending, setIntroPending] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const intro = await AsyncStorage.getItem("introCompleted");
      if (intro) {
        setIntroPending(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setIntroPending(
      config && config["app.config.feature.showIntroSlider"] == "false"
        ? false
        : true
    );
  }, [config]);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchToken() {
      const token = await AsyncStorage.getItem("access_token");
      const requests = [dispatch(getLookup()), dispatch(getConfig())];
      if (token) {
        requests.push(dispatch(getProfile(token)));
      }

      await Promise.all(requests);
      setIsTryingLogin(false);
    }

    fetchToken();
  }, []);
  const handleInroComplete = () => {
    AsyncStorage.setItem("introCompleted", true);
    setIntroPending(false);
  };

  useEffect(() => {
    const fetchFont = async () => {
      Font.loadAsync({
        SFPro: require("../assets/fonts/SFPro.ttf"),
      });
    };
    fetchFont();
  }, []);

  if (isTryingLogin) {
    if (Platform.OS === "web") {
      return (
        <View style={[{ ...styles.container }, { backgroundColor: "white" }]}>
          <Image
            source={{
              uri: require("../assets/splash.svg"),
            }}
            style={{ width: "100%", height: "100%" }}
          />
        </View>
      );
    }
    return <AppLoading />;
  }

  return (
    <>
      {introPending && (
        <View style={[StyleSheet.absoluteFillObject, { zIndex: 100 }]}>
          <IntroSlider handleLastScreen={handleInroComplete} />
        </View>
      )}

      <NavigationContainer
        linking={linking}
        theme={{
          colors: {
            background: "F8F8F8", // Set the background color to white globally
          },
        }}
      >
        <View style={styles.container}>
          {notification?.loading && (
            <Progress.Bar
              animated
              indeterminate
              unfilledColor="#90caf9"
              color="#2196f3"
              borderWidth={0}
              width={null}
              useNativeDriver
              borderRadius={0}
            />
          )}
          {notification?.show && !!notification?.text && (
            <TopNotification message={notification?.text} />
          )}
          {/* <Suspense fallback={<LoadingComponent />}> */}

          <LoggedInNavigator />
          <OpeningModals />

          {/* </Suspense> */}
        </View>
      </NavigationContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  notification: state.notification,
  isLoggedIn: state.user.isLoggedIn,
  isNameRequired: state.user.isNameRequired,
  config: state?.cms?.config,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CoreScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
