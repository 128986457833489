import { Pressable, StyleSheet, Platform, Text, View } from "react-native";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";

import { useState } from "react";
import { notificationActions } from "../store/slices/notification";
import CustomText from "../components/CustomText";
import { setName } from "../store/actions/auth";
import { loginRequired } from "../utils/loginRequired";

const EditProfileScreen = ({ profileData, token, navigation }) => {
  const [userData, setUserData] = useState({
    first_name: profileData?.first_name,
    email: profileData?.email,
  });

  useEffect(() => {
    if (!token) loginRequired(navigation);
  }, []);

  const dispatch = useDispatch();
  return (
    <View style={styles.container}>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Profile</CustomText>
      </View>
      <CustomText style={styles.phoneHeading}>Name</CustomText>
      <View style={styles.numberContainer}>
        <Input
          placeholder="Name"
          defaultValue={profileData?.first_name}
          styleProp={{
            // borderColor: "#f09aab",
            // borderWidth: 1,
            padding: 12,
            borderRadius: 12,
          }}
          onChangeText={(e) => {
            setUserData((prev) => ({
              ...prev,
              first_name: e,
            }));
          }}
        />
      </View>
      <CustomText style={styles.phoneHeading}>Email</CustomText>
      <View style={styles.numberContainer}>
        <Input
          placeholder="Email"
          styleProp={{
            // borderColor: "#f09aab",
            // borderWidth: 1,
            padding: 12,
            borderRadius: 12,
          }}
          defaultValue={profileData?.email}
          onChangeText={(e) => {
            setUserData((prev) => ({
              ...prev,
              email: e,
            }));
          }}
        />
      </View>
      <CustomText style={styles.phoneHeading}>Mobile</CustomText>
      <View style={styles.numberContainer}>
        <Input
          placeholder="Mobile"
          defaultValue={profileData?.mobile}
          readOnly={true}
          styleProp={{
            // borderColor: "#f09aab",
            // borderWidth: 1,
            padding: 12,
            borderRadius: 12,
          }}
        />
      </View>
      <View style={styles.lowerContainer}>
        <Pressable
          style={styles.button}
          onPress={async () => {
            dispatch(notificationActions.toggleError());
            if (
              userData.first_name &&
              userData.first_name.length !== 0 &&
              (!userData.email || userData?.email.includes("@"))
            ) {
              await dispatch(setName(token, { ...userData }));
              navigation.pop();
            } else {
              dispatch(
                notificationActions.toggleError("Invalid Name or Email")
              );
            }
          }}
        >
          <Text style={styles.buttonText}>Update Profile</Text>
        </Pressable>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.user.profileData,
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    rowGap: 20,
  },
  upperContainer: {
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
  mainHeading: {
    color: "#DB5E71",
    fontSize: 28,
  },

  phoneHeading: {
    color: "#000",
    fontSize: 16,
  },
  numberContainer: {
    flexDirection: "row",
    width: "100%",
    gap: 28,
    justifyContent: "space-between",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#DB5E71",
    width: "90%",
    paddingVertical: 20,
    borderRadius: 20,
    paddingHorizontal: 20,
    position: "fixed",
    bottom: 80,
    maxWidth: 440,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
});
