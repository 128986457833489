import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  loading: false,
  loadingSpinner: false,
  modalVisible: false,
  modalShareVisible: false,
  selectedOrder: "",
  selectedOrderDetail: {},
  text: "",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    toggleLoading(state, action) {
      state.loading = action.payload;
    },
    toggleSpinnerLoading(state, action) {
      state.loadingSpinner = action.payload;
    },
    setSelectedOrder(state, action) {
      state.selectedOrder = action.payload;
    },
    setSelectedOrderDetail(state, action) {
      state.selectedOrderDetail = action.payload;
    },
    setModalVisible(state, action) {
      state.modalVisible = action.payload;
    },
    setModalShareVisible(state, action) {
      state.modalShareVisible = action.payload;
    },
    toggleError(state, action) {
      if (action.payload) {
        state.show = true;
        state.text = action.payload;
      } else {
        state.show = false;
      }
    },
  },
});

export const notificationActions = notificationSlice.actions;

export default notificationSlice.reducer;
