import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
  Linking,
  CheckBox,
  TouchableOpacity,
} from "react-native";
import React, { useLayoutEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo, Octicons } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { userActions } from "../store/slices/auth";
import { notificationActions } from "../store/slices/notification";
import { useEffect } from "react";
import CustomText from "../components/CustomText";
import IntroSlider from "../components/IntroSlider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";

const LoginScreen = ({
  otpSent,
  isLoggedIn,
  navigation,
  route,
  isNameRequired,
  tncLink,
}) => {
  const [userData, setUserData] = useState({ countryCode: "91" });
  const [tncAccepted, setTncAccepted] = useState(false);
  const dispatch = useDispatch();

  const disableSkip = route?.params?.byReview;

  useEffect(() => {
    if (isLoggedIn) {
      if (isNameRequired) navigation.replace("ProfileShort");
      else
        navigation.replace("Homeroutes", {
          screen: "Dashboard",
        });
    }
    return () => {
      dispatch(userActions.setOtpSent(false));
    };
  }, [isLoggedIn]);

  return (
    <View style={styles.container}>
      <View style={styles.centerContainer}>
        <View>
          <CustomText style={styles.mainHeading}>Sign In</CustomText>
        </View>
      </View>

      <CustomText style={styles.phoneHeading}>Phone Number</CustomText>
      <View style={styles.numberContainer}>
        <View style={[{ flex: 1 }]}>
          <Input
            keyboardType="phone-pad"
            maxLength={3}
            Icon={
              <Entypo
                name="plus"
                size={12}
                style={{ position: "absolute", left: 12 }}
                color="black"
              />
            }
            value={userData.countryCode}
            styleProp={{
              borderRadius: 12,
            }}
            editable={!otpSent}
            onChangeText={(e) => {
              setUserData((prev) => ({
                ...prev,
                countryCode: e,
              }));
            }}
          />
        </View>
        <View style={{ flex: 5 }}>
          <Input
            placeholder="Mobile"
            keyboardType="phone-pad"
            maxLength={10}
            // value={userData.mobile}
            styleProp={{
              // borderColor: "#f09aab",
              // borderWidth: 1,
              padding: 12,
              borderRadius: 12,
            }}
            editable={!otpSent}
            onChangeText={(e) => {
              setUserData((prev) => ({
                ...prev,
                mobile: e,
              }));
            }}
          />
        </View>
      </View>

      {otpSent && (
        <>
          <CustomText style={styles.phoneHeading}>OTP</CustomText>
          <View style={styles.numberContainer}>
            <Input
              // label="OTP"
              placeholder="Enter OTP"
              keyboardType="phone-pad"
              styleProp={{
                // borderColor: "#f09aab",
                // borderWidth: 1,
                padding: 12,
                borderRadius: 12,
              }}
              onChangeText={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  password: e,
                }));
              }}
            />
            {/* checkbox */}
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              columnGap: 8,
            }}
          >
            <CheckBox
              value={tncAccepted}
              onValueChange={(e) => {
                setTncAccepted(e);
              }}
            />
            <CustomText style={{ color: "#858490" }}>
              By logging in you accept all{" "}
              <TouchableOpacity
                style={{ textDecorationLine: "underline" }}
                onPress={() => Linking.openURL(tncLink)}
              >
                T&C
              </TouchableOpacity>
            </CustomText>
          </View>
        </>
      )}
      {!disableSkip && (
        <Pressable
          onPress={async () => {
            await AsyncStorage.setItem("isSkipedLogin", true);
            navigation.reset({
              index: 0,
              routes: [{ name: "ProfileCards" }],
            });
            // navigation.replace("ProfileCards");
          }}
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomText style={{ color: "#858490" }}>{"Skip >>"}</CustomText>
        </Pressable>
      )}
      <View style={styles.lowerContainer}>
        <Pressable
          style={styles.button}
          onPress={async () => {
            dispatch(notificationActions.toggleError());
            if (!tncAccepted && otpSent) {
              return dispatch(notificationActions.toggleError("Accept T&C"));
            }
            if (userData.mobile && userData.mobile.length === 10) {
              // localStorage.setItem("mobile", mobile);
              !otpSent
                ? await dispatch(generateOtp({ ...userData }))
                : userData.password && userData.password.length === 4
                ? await dispatch(verifyOtp({ ...userData }))
                : dispatch(notificationActions.toggleError("Enter valid OTP"));
              // dispatch(login({mobile}));
            } else {
              dispatch(
                notificationActions.toggleError("Invalid Mobile Number")
              );
            }
          }}
        >
          <CustomText style={styles.buttonText}>
            {otpSent ? "Verify" : "Get OTP"}
          </CustomText>
        </Pressable>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  otpSent: state.user.otpSent,
  isLoggedIn: state.user.isLoggedIn,
  isNameRequired: state.user.isNameRequired,
  tncLink: state?.cms?.config["app.cms.url.tnc"],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 80,
    backgroundColor: "#F8F8F8",
    rowGap: 20,
  },
  centerContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15%",
  },
  mainHeading: {
    color: "#DB5E71",
    fontSize: 28,
  },
  secondaryHeading: {
    color: "#7b7b7b",
    fontSize: 16,
  },
  middleContainer: {
    borderRadius: 20,
    alignItems: "flex-start",
    backgroundColor: "#DB5E71",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 16,
  },
  numberContainer: {
    flexDirection: "row",
    width: "100%",
    gap: 12,
    justifyContent: "space-between",
  },
  prefixContainer: {
    backgroundColor: "#fff",
    padding: 12,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#DB5E71",
    width: "90%",
    paddingVertical: 20,
    borderRadius: 20,
    paddingHorizontal: 20,
    position: "fixed",
    bottom: 80,
    maxWidth: 440,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
});
