import { StatusBar } from "expo-status-bar";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { Provider } from "react-redux";
import store from "./store/store";
import CoreScreen from "./screens/CoreScreen";

const App = () => {
  return (
    <SafeAreaView style={styles.safeArea}>
      <Provider store={store}>
        <View style={styles.container}>
          <StatusBar style="dark" />
          <View style={styles.innerContainer}>
            <CoreScreen />
          </View>
        </View>
      </Provider>
    </SafeAreaView>
  );
};

export default App;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    // backgroundColor: "transparent",
  },
  container: {
    flex: 1,
    backgroundColor: "#a2a2a2",
    alignItems: "center",
  },
  innerContainer: {
    maxWidth: 480,
    backgroundColor: "#F8F8F8",
    flex: 1,
    width: "100%",
  },
});
