import { API_BASE_URL } from "../../config";
import {
  convertArraysToObject,
  convertObjectsToArray,
  getSortedArray,
} from "../../utils/convertArrayToObject";
import { userActions } from "../slices/auth";
import { cmsActions } from "../slices/cms";
import { notificationActions } from "../slices/notification";
import { profileActions } from "../slices/profile";
const { v4: uuidv4 } = require("uuid");

export const getConfig = () => {
  return async (dispatch) => {
    const res = await fetch(
      `${API_BASE_URL}api-public/config/c29fb057-5da3-4024-ae9e-9d3ca9ac0373`
    );
    // console.log(res)
    if (!res.ok) {
      //   dispatch(notificationActions.toggleLoading(false));
      dispatch(notificationActions.toggleError("Failed fetching CMS"));
      return;
    } else {
      const data = await res.json();
      dispatch(cmsActions.setConfig(data));
    }
  };
};

export const getLookup = () => {
  return async (dispatch) => {
    const res = await fetch(`${API_BASE_URL}api-public/lookup`);
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed fetching CMS"));
      return;
    } else {
      const data = await res.json();
      dispatch(cmsActions.setLookup(data));
    }
  };
};

export const getDashboard = (stop) => {
  return async (dispatch) => {
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/dashboard`);
    if (!res.ok) {
      dispatch(
        notificationActions.toggleError("Failed fetching dashboardData")
      );
      if (stop) dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    } else {
      const data = await res.json();
      dispatch(cmsActions.setDashboardData(data));
    }
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(false));
  };
};

export const getProduct = (id) => {
  return async (dispatch) => {
    dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/product/${id}`);
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed fetching product data"));
      dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    } else {
      const data = await res.json();
      dispatch(cmsActions.setProductData(data));
    }
    dispatch(notificationActions.toggleSpinnerLoading(false));
  };
};

export const getProducts = (stop) => {
  return async (dispatch) => {
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/products`);
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed fetching products"));
      if (stop) dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    } else {
      const data = await res.json();
      dispatch(cmsActions.setProductsList(data));
    }
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(false));
  };
};

export const getOrders = (token, stop) => {
  return async (dispatch) => {
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/orders`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed fetching orders"));
      if (stop) dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    } else {
      const data = await res.json();
      dispatch(cmsActions.setOrdersList(data));
    }
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(false));
  };
};

export const getCoupons = (token, stop) => {
  return async (dispatch) => {
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/coupon`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed fetching coupons"));
      if (stop) dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    } else {
      const data = await res.json();
      dispatch(cmsActions.setCoupons(data));
    }
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(false));
  };
};

export const getReviews = (token, id, stop) => {
  return async (dispatch) => {
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/rating_review/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed fetching reviews"));
      if (stop) dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    } else {
      const data = await res.json();
      dispatch(cmsActions.setReviewsList(data));
    }
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(false));
  };
};

export const submitReview = (token, data) => {
  return async (dispatch) => {
    dispatch(notificationActions.toggleLoading(true));
    const body = JSON.stringify(data);
    const res = await fetch(`${API_BASE_URL}api-public/rating_review`, {
      headers: {
        Authorization: token,
      },
      method: "POST",
      body,
    });
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed Submiting reviews"));
      dispatch(notificationActions.toggleLoading(false));

      return;
    }
    const response = await res.json();

    dispatch(notificationActions.toggleLoading(false));
    return response;
  };
};

export const generatePdfLink = (id, url, extra = {}, stop) => {
  console.log(extra, url);
  return async (dispatch, getState) => {
    const config = getState()?.cms?.config;
    const token = getState()?.user?.token;

    const body = JSON.stringify({
      objectKey: id,
      override: true,
      bucketName: config["app.service.pdf.path"],
      url: url ? url : `${config["app.template.share_url.v2"]}${id}`,
      user_id: token,
      ...extra,
    });
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(
      `https://app.khojorightnow.com/api/${config["app.service.pdf.url"]}`,
      {
        headers: {
          Authorization: token,
        },
        body,
        method: "POST",
      }
    );
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed generating pdf"));
      if (stop) dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    }
    const data = await res.json();
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(false));
    return data;
  };
};

export const getTemplateData = (token, id) => {
  return async (dispatch) => {
    dispatch(notificationActions.toggleLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/template_data/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.ok) {
      dispatch(
        notificationActions.toggleError("Failed fetching template data")
      );
      dispatch(notificationActions.toggleLoading(false));

      return;
    }

    const data = await res.json();
    dispatch(notificationActions.toggleLoading(false));
    return data;
  };
};

export const getShareMessage = (token, data) => {
  return async (dispatch) => {
    const body = JSON.stringify(data);
    dispatch(notificationActions.toggleLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/dmessages`, {
      headers: {
        Authorization: token,
      },
      method: "POST",
      body,
    });
    if (!res.ok) {
      dispatch(
        notificationActions.toggleError("Failed fetching sharing message")
      );
      dispatch(notificationActions.toggleLoading(false));
      return;
    }

    const returnData = await res.json();
    dispatch(notificationActions.toggleLoading(false));
    return returnData;
  };
};

export const getTemplateFormData = (token) => {
  return async (dispatch) => {
    // dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/cms`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed fetching Form data"));
      // dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    }

    const data = await res.json();
    // dispatch(notificationActions.toggleSpinnerLoading(false));
    dispatch(cmsActions.setFormData(data.template_data_form));
  };
};

export const getTemplatePreData = (token, edit, stop) => {
  return async (dispatch, getState) => {
    if (stop) dispatch(notificationActions.toggleLoading(true));
    const res = await fetch(
      `${API_BASE_URL}api-public/template_data${
        edit ? `/${edit}` : "?version=2"
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed fetching Form data"));
      if (stop) dispatch(notificationActions.toggleLoading(false));

      return;
    }

    const data = await res.json();
    if (data) {
      if (data?.data?.username && !edit) {
        delete data.data.username;
      }
      if (edit) {
        const {
          product_id: templateId,
          profile_category_id: categoryId,
          profile_category: categoryTitle,
          profile_sub_category_id: subCategoryId,
          profile_sub_category: subCategoryTitle,
          quick_information_quick_information: qi,
        } = data.data;
        await dispatch(
          profileActions.setAllData({
            templateId,
            categoryId,
            categoryTitle,
            subCategoryId,
            subCategoryTitle,
          })
        );
        const setData = convertObjectsToArray(data.data);
        await dispatch(profileActions.setTemplateData(setData));
        if (setData.quick_information_quick_information) {
          dispatch(
            profileActions.updateTemplateData({
              key: "quick_information_quick_information",
              value: getSortedArray(
                setData.quick_information_quick_information,
                "quick_information_quick_information"
              ),
            })
          );
        }
      } else {
        const catId = getState()?.profile?.categoryId;
        if (data?.data?.profile_category_id === catId) {
          const setData = convertObjectsToArray(data.data);
          await dispatch(profileActions.setTemplateData(setData));
          if (setData.quick_information_quick_information) {
            dispatch(
              profileActions.updateTemplateData({
                key: "quick_information_quick_information",
                value: getSortedArray(
                  setData.quick_information_quick_information,
                  "quick_information_quick_information"
                ),
              })
            );
          }
        }
      }
    }
    if (stop) dispatch(notificationActions.toggleLoading(false));
  };
};

export const getTemplateMesasgeData = (token, stop) => {
  return async (dispatch) => {
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/template_messages`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.ok) {
      dispatch(
        notificationActions.toggleError("Failed fetching Form Message data")
      );
      if (!stop) dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    }

    const data = await res.json();
    dispatch(cmsActions.setFormMessageData(data));
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(false));
  };
};

export const uploadPhoto = (token, data) => {
  return async (dispatch) => {
    dispatch(notificationActions.toggleLoading(true));
    const res = await fetch(`https://media.khojorightnow.com/v1/api/upload`, {
      headers: {
        Authorization: token,
      },
      method: "POST",
      body: data,
    });
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed uploading photo"));
      dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    }
    const response = await res.json();

    dispatch(notificationActions.toggleLoading(false));
    return response;
  };
};

export const submitTemplateData = (token, put) => {
  return async (dispatch, getState) => {
    const { profile } = getState();
    const templateId = profile?.templateId;
    const categoryId = profile?.categoryId;
    const categoryTitle = profile?.categoryTitle;
    const subCategoryId = profile?.subCategoryId;
    const subCategoryTitle = profile?.subCategoryTitle;
    const data = profile?.templateData;
    dispatch(notificationActions.toggleLoading(true));
    const body = JSON.stringify(
      convertArraysToObject({
        ...data,
        product_id: templateId,
        profile_category_id: categoryId,
        profile_category: categoryTitle,
        profile_sub_category_id: subCategoryId,
        profile_sub_category: subCategoryTitle,
        version: 2,
      })
    );

    navigator.clipboard.writeText(
      JSON.stringify(
        convertArraysToObject({
          ...data,
          product_id: templateId,
          profile_category_id: categoryId,
          profile_category: categoryTitle,
          profile_sub_category_id: subCategoryId,
          profile_sub_category: subCategoryTitle,
          version: 2,
        })
      )
    );

    const res = await fetch(
      `${API_BASE_URL}api-public/template_data${put ? `/${put}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
        method: put ? "PUT" : "POST",
        body,
      }
    );
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed Submiting reviews"));
      dispatch(notificationActions.toggleLoading(false));

      return;
    }
    const response = await res.json();

    dispatch(notificationActions.toggleLoading(false));
    return response;
  };
};

export const validateOrder = (token, data) => {
  return async (dispatch) => {
    const body = JSON.stringify(data);
    dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/order/validate`, {
      headers: {
        Authorization: token,
      },
      body,
      method: "POST",
    });
    if (!res.ok) {
      dispatch(
        notificationActions.toggleError("Failed fetching Form Message data")
      );
      dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    }

    const response = await res.json();
    dispatch(notificationActions.toggleSpinnerLoading(false));
    return response;
  };
};

export const completePayment = (token, id) => {
  return async (dispatch) => {
    const body = JSON.stringify({
      payment_id: uuidv4(),
      message: "amount 0",
      status: true,
    });
    dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/payment/${id}`, {
      headers: {
        Authorization: token,
      },
      body,
      method: "POST",
    });
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed creating order"));
      dispatch(notificationActions.toggleSpinnerLoading(false));

      return true;
    }

    const response = await res.json();
    dispatch(notificationActions.toggleSpinnerLoading(false));
    return response;
  };
};

export const placeOrder = (token, data) => {
  return async (dispatch) => {
    const body = JSON.stringify(data);
    dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(`${API_BASE_URL}api-public/order`, {
      headers: {
        Authorization: token,
      },
      body,
      method: "POST",
    });
    if (!res.ok) {
      dispatch(
        notificationActions.toggleError("Failed fetching Form Message data")
      );
      dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    }

    const response = await res.json();
    if (response.status !== 1)
      dispatch(notificationActions.toggleSpinnerLoading(false));
    return response;
  };
};

export const getOrder = (token, id) => {
  return async (dispatch) => {
    dispatch(notificationActions.toggleSpinnerLoading(true));

    const res = await fetch(`${API_BASE_URL}api-public/orders/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed fetching order detail"));
      dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    }
    const data = await res.json();
    dispatch(cmsActions.setOrderData(data));
    dispatch(notificationActions.toggleSpinnerLoading(false));
    return data;
  };
};

export const checkUsername = (token, id, stop, getSuggestion) => {
  return async (dispatch) => {
    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(true));
    const res = await fetch(
      `${API_BASE_URL}api-public/username/${id}${getSuggestion ? "/1" : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (!res.ok) {
      dispatch(notificationActions.toggleError("Failed checking username"));
      if (stop) dispatch(notificationActions.toggleSpinnerLoading(false));

      return;
    }
    const data = await res.json();
    // dispatch(cmsActions.setReviewsList(data));

    if (!stop) dispatch(notificationActions.toggleSpinnerLoading(false));
    return data;
  };
};
