import {
  ActivityIndicator,
  Linking,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  AntDesign,
  Entypo,
  FontAwesome,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import ListView from "../components/ListView";
import { getOrder, getTemplateData, validateOrder } from "../store/actions/cms";
import { generateShareContent } from "../utils/generateMessage";
import shareMessage from "../utils/shareMessage";
import CustomText from "../components/CustomText";
import { loginRequired } from "../utils/loginRequired";
import { notificationActions } from "../store/slices/notification";

const CheckoutScreen = ({
  loading,
  token,
  getTemplateData,

  navigation,
  config,
  setSelectedOrder,
  setSelectedOrderDetail,
  setModalVisible,
  setModalShareVisible,
  order,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      if (!order) {
        setTimeout(() => navigation.replace("Dashboard"), 100);
      }
    } else {
      loginRequired(navigation);
    }
  }, [token]);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#DB5E71" />
      </View>
    );
  }

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Order Details</CustomText>
        <CustomText
          style={{
            fontWeight: 300,

            color: "#8D8D8D",
            fontSize: 16,
          }}
        >
          {order?.payment_info && order?.payment_info[0]?.is_paid
            ? "Yeah! you have placed your order successfully"
            : "Oh Noo..!! your payment was not completed"}
        </CustomText>
      </View>

      {order && (
        <View style={styles.container}>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 32,
              borderWidth: 8,
              borderColor: "#ececec",
              borderRadius: "50%",
            }}
          >
            <AntDesign
              name={
                order?.payment_info && order?.payment_info[0]?.is_paid
                  ? "like1"
                  : "dislike1"
              }
              size={60}
              color="#DB5E71"
            />
          </View>
          <CustomText>
            <CustomText style={styles.secondaryOptionHeading}>
              {order?.payment_info && order?.payment_info[0]?.is_paid
                ? "Congratulations!"
                : "Oh Noo..!!"}
            </CustomText>
            {order?.payment_info && order?.payment_info[0]?.is_paid
              ? "Your order has been placed"
              : "The transaction has failed"}
          </CustomText>
          <CustomText>Order id : #{order.order_id.split("-")[0]}</CustomText>
          <CustomText style={styles.mainOptionHeading}>
            LOVING KHOJO RIGHT NOW?
          </CustomText>
          <Pressable
            onPress={() => {
              Linking.openURL(config["app.cms.url.play_store"]);
            }}
          >
            <CustomText
              style={[styles.secondaryOptionHeading, { color: "#DB5E71" }]}
            >
              Rate this app now!
            </CustomText>
          </Pressable>

          {order?.payment_info && order?.payment_info[0]?.is_paid && (
            <View
              style={{
                width: "100%",
                columnGap: 5,
                flexDirection: "row",
                marginVertical: 20,
              }}
            >
              <Pressable
                style={styles.button}
                onPress={async () => {
                  setSelectedOrderDetail(order);
                  setModalShareVisible(true);
                }}
              >
                <Ionicons name="share-social-sharp" size={16} color="white" />
                <CustomText style={styles.buttonText}>Share</CustomText>
              </Pressable>
              <Pressable
                style={styles.button}
                onPress={async () => {
                  setSelectedOrder(order?.items[0]?.username);
                  setSelectedOrderDetail(order);
                  setModalVisible(true);
                }}
              >
                <AntDesign name="arrowdown" size={16} color="white" />
                <CustomText style={styles.buttonText}>Download</CustomText>
              </Pressable>
            </View>
          )}

          {order?.payment_info && order?.payment_info[0]?.is_paid && (
            <View
              style={{
                width: "100%",
                columnGap: 5,
                flexDirection: "row",
              }}
            >
              <Pressable
                style={styles.button}
                onPress={() => {
                  navigation.navigate("Pages", {
                    isOrderDetail: 1,
                    id: order?.items[0]?.username,
                  });
                }}
              >
                <CustomText style={styles.buttonText}>View Profile</CustomText>
              </Pressable>
            </View>
          )}
        </View>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.notification?.loadingSpinner,
  order: state?.cms?.order,
  config: state?.cms?.config,
  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({
  getOrder: (token, data) => dispatch(getOrder(token, data)),

  getTemplateData: (token, id) => dispatch(getTemplateData(token, id)),
  setModalVisible: (data) =>
    dispatch(notificationActions.setModalVisible(data)),
  setSelectedOrder: (data) =>
    dispatch(notificationActions.setSelectedOrder(data)),
  setSelectedOrderDetail: (data) =>
    dispatch(notificationActions.setSelectedOrderDetail(data)),
  setModalShareVisible: (data) =>
    dispatch(notificationActions.setModalShareVisible(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 12,
    padding: 16,
    alignItems: "center",
  },
  divider: {
    width: "100%",
    marginVertical: 2,
    height: StyleSheet.hairlineWidth,
    backgroundColor: "#a2a2a2",
  },

  mainOptionHeading: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
  secondaryOptionHeading: {
    color: "#252525",
    fontSize: 12,
    fontWeight: "bold",
  },
  upperContainer: {
    padding: 16,
    rowGap: 16,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#DB5E71",
    fontSize: 32,
    fontWeight: 600,
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#DB5E71",
    flexDirection: "row",
    flex: 1,
    marginVertical: 4,
    padding: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 8,
  },
  buttonText: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
});
