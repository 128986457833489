import {
  ActivityIndicator,
  Image,
  Pressable,
  Platform,
  ScrollView,
  Linking,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { LinearGradient } from "expo-linear-gradient";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { userActions } from "../store/slices/auth";
import { notificationActions } from "../store/slices/notification";
import { useEffect } from "react";
import ProfileList from "../components/ProfileList";
import ImageSlider from "../components/ImageSlider";
import { getDashboard, getOrders } from "../store/actions/cms";
import CategoryList from "../components/CategoryList";
import Footer from "../components/Footer";
import { profileActions } from "../store/slices/profile";
import { useFocusEffect } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import OrderList from "../components/OrderList";
import CustomText from "../components/CustomText";

const Dashboard = ({
  profileData,
  getData,
  dashboardData,
  getOrderData,
  orderData,
  loading,
  navigation,
  name,
  banner,
  token,
}) => {
  const dispatch = useDispatch();

  const fetchData = async () => {
    let initialUrl = await Linking.getInitialURL();
    let isSkipedLogin = await AsyncStorage.getItem("isSkipedLogin");

    initialUrl =
      initialUrl.includes("dashboard") ||
      initialUrl.split("/").filter((i) => !!i).length === 2;

    if (!isSkipedLogin && !token && initialUrl) {
      return navigation.replace("Login");
    }

    dispatch(notificationActions.toggleSpinnerLoading(true));
    const requests = [];
    if (!dashboardData) {
      requests.push(getData(1));
    }
    if (!orderData && token) {
      requests.push(getOrderData(token, 1));
    }
    if (requests.length === 0)
      requests.push(dispatch(notificationActions.toggleSpinnerLoading(false)));
    await Promise.all(requests);
    dispatch(notificationActions.toggleSpinnerLoading(false));
    // }
  };

  useFocusEffect(() => {
    fetchData();
    dispatch(profileActions.resetProfileSelection());
  });

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <LinearGradient
      colors={["#FC9E98", "#FFD8C2", "#F8F8F8"]}
      start={[0, 0]}
      end={[0.1, 0.1]}
      locations={[0, 0.08, 0.24]}
      style={styles.container}
    >
      <ScrollView
        style={[styles.container]}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.upperContainer}>
          <CustomText style={styles.mainHeading}>
            Hello {name ?? "User"},
          </CustomText>
        </View>

        {orderData && orderData?.length !== 0 ? (
          <OrderList data={orderData} title="Your Profiles" />
        ) : (
          <View style={{ paddingHorizontal: 16, width: "100%" }}>
            <CustomText
              style={{ fontWeight: 600, fontSize: 16, textAlign: "center" }}
            >
              Namaste!🙏🏻 It seems You haven’t Created your profile yet! Tap to
              Create New Profile Below.
            </CustomText>
            <Image
              style={{ width: "100%", aspectRatio: 1 / 1 }}
              source={{
                uri: "https://bin-cdn-krn.s3.us-east-2.amazonaws.com/original/28635a59-3221-4ea2-9204-2d96ac5dcabb.png",
              }}
            />
          </View>
        )}

        {profileData && <ProfileList data={profileData} />}
        {/* {banner && <ImageSlider data={banner} />} */}
        {dashboardData?.catgeory_display &&
          dashboardData?.catgeory_display?.length > 0 &&
          dashboardData?.showcase &&
          dashboardData?.catgeory_display.map((category) => {
            const data = dashboardData?.showcase.filter(
              (item) => item.cat_id == category.category_id
            );
            return (
              data &&
              data.length > 0 && (
                <CategoryList title={category.name} data={data} />
              )
            );
          })}
        <Footer />
      </ScrollView>
      <View
        style={{
          alignItems: "center",
          position: "fixed",
          bottom: 80,
          width: "100%",
          rowGap: 8,
          maxWidth: 480,
        }}
      >
        <Pressable
          style={styles.button}
          onPress={() => navigation.navigate("ProfileCards")}
        >
          <CustomText style={styles.buttonText}>Create New Profile</CustomText>
        </Pressable>
        {!name && (
          <Pressable
            onPress={() => {
              navigation.navigate("Login");
            }}
            style={styles.button}
          >
            <CustomText style={styles.buttonText}>Login</CustomText>
          </Pressable>
        )}
      </View>
    </LinearGradient>
  );
};

const mapStateToProps = (state) => ({
  name: state?.user?.profileData?.first_name,
  profileData: state?.cms?.lookup?.profile_category,
  banner: state?.cms?.lookup?.banner,
  token: state?.user?.token,
  dashboardData: state?.cms?.dashboard,
  orderData: state?.cms?.orders,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (stop) => dispatch(getDashboard(stop)),
  getOrderData: (token) => dispatch(getOrders(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 8,
    // backgroundColor: "white",
  },
  upperContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: 16,
    padding: 16,
    paddingTop: 32,
  },
  pressed: {
    opacity: 0.7,
    backgroundColor: "#d9d9d9",
  },
  mainHeading: {
    color: "#DB5E71",
    fontSize: 28,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#252525",
    fontSize: 16,
    fontWeight: "bold",
  },
  middleContainer: {
    alignItems: "center",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
  gradient: {
    width: 200,
    height: 200,
    borderRadius: 100, // Half the width and height to make it a circle
  },
  numberContainer: {
    flexDirection: "row",
    width: "100%",
    gap: 28,
    justifyContent: "space-between",
  },
  prefixContainer: {
    backgroundColor: "#fff",
    padding: 12,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#DB5E71",
    paddingVertical: 20,
    borderRadius: 20,
    paddingHorizontal: 20,
    width: "90%",
    maxWidth: 480,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
});
