import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  FlatList,
} from "react-native";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, setName, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { notificationActions } from "../store/slices/notification";
import CustomText from "../components/CustomText";
import { loginRequired } from "../utils/loginRequired";
import {
  checkUsername,
  getTemplatePreData,
  submitTemplateData,
} from "../store/actions/cms";
import { profileActions } from "../store/slices/profile";
import { generateRegex } from "../utils/generateRegex";

const SelectUsername = ({
  token,
  route,
  navigation,
  minLength,
  maxLength,
  baseUrl,
}) => {
  const { templateId, productId } = route.params;
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState();
  const regex = generateRegex(minLength, maxLength);

  useEffect(() => {
    if (token) {
      if (!templateId || !productId) {
        setTimeout(() => navigation.replace("Dashboard"), 100);
      }
    } else loginRequired(navigation);
  }, [token]);

  const [userData, setUserData] = useState({
    user_name: "",
  });
  const dispatch = useDispatch();
  return (
    <View style={styles.container}>
      <View style={{ rowGap: 10 }}>
        <CustomText style={styles.mainHeading}>Choose Username?</CustomText>
        <CustomText style={styles.secondaryHeading}>
          This username will be used to create url for your profiles
        </CustomText>
      </View>
      <CustomText style={styles.phoneHeading}>Username</CustomText>
      <View style={styles.numberContainer}>
        <Input
          placeholder="Name"
          defaultValue={userData?.user_name}
          styleProp={{
            // borderColor: "#f09aab",
            // borderWidth: 1,
            padding: 12,
            borderRadius: 12,
          }}
          onChangeText={(e) => {
            setUserData((prev) => ({
              ...prev,
              user_name: e,
            }));
          }}
        />
      </View>
      <CustomText
        style={[
          styles.secondaryHeading,
          { fontSize: 12, textAlign: "center", paddingTop: "5%" },
        ]}
      >
        {`Username must be between ${minLength} to ${maxLength} characters with no spaces and special
        characters expect - and _`}
      </CustomText>
      {!!userData.user_name && regex.test(userData.user_name) ? (
        <CustomText
          style={[
            styles.secondaryHeading,
            {
              fontSize: 12,
              textAlign: "center",
              paddingBottom: "10%",
              fontWeight: 600,
            },
          ]}
        >
          Your profile will look like{"\n"}
          <CustomText style={{ color: "#598c66", fontSize: 14 }}>
            {`${baseUrl}${userData.user_name}`.toLowerCase()}
          </CustomText>
        </CustomText>
      ) : (
        <CustomText
          style={[
            styles.secondaryHeading,
            {
              fontSize: 12,
              fontWeight: 600,
              textAlign: "center",
              paddingBottom: "10%",
              color: "#ec644b",
            },
          ]}
        >
          Please meet above conditions for a valid username
        </CustomText>
      )}

      {/* suggestion list */}

      {suggestions.length > 0 && (
        <FlatList
          data={suggestions}
          showsVerticalScrollIndicator={false}
          renderItem={(itemData) => {
            return (
              <TouchableOpacity
                style={{
                  marginBottom: 16,
                  marginHorizontal: 16,
                  flexDirection: "row",
                  overflow: "hidden",
                  borderRadius: 20,
                  ...Platform.select({
                    web: {
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    },
                    android: {
                      elevation: 10,
                    },
                    ios: {
                      shadowColor: "rgba(0, 0, 0, 0.16)",
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                      shadowOpacity: 1,
                      shadowRadius: 4,
                    },
                  }),
                }}
                onPress={() => {
                  setIsFocused(itemData.index);
                  setUserData((prev) => ({
                    ...prev,
                    user_name: itemData.item,
                  }));
                }}
              >
                <View
                  style={{
                    flex: 9,
                    padding: 16,
                    backgroundColor:
                      itemData.index === isFocused ? "#DB5E71" : "#E7E7E7",
                    rowGap: 12,
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <CustomText
                    style={{
                      fontSize: 20,
                      color: itemData.index !== isFocused ? "#000" : "#fff",
                    }}
                  >
                    {itemData.item}
                  </CustomText>
                </View>
              </TouchableOpacity>
            );
          }}
          // keyExtractor={(item) => {
          //   return item.profile_id;
          // }}
          alwaysBounceVertical={false}
          ListHeaderComponent={
            <View
              style={{
                width: "100%",
                paddingVertical: 16,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomText style={{ fontSize: 16 }}>Suggestions</CustomText>
            </View>
          }
        />
      )}

      <Pressable
        style={styles.button}
        onPress={async () => {
          dispatch(notificationActions.toggleError());
          if (userData.user_name && regex.test(userData.user_name)) {
            const data = await dispatch(
              checkUsername(token, userData.user_name)
            );
            if (data.status) {
              await dispatch(getTemplatePreData(token, templateId));
              await dispatch(
                profileActions.updateTemplateData({
                  key: "username",
                  value: userData.user_name,
                })
              );
              await dispatch(submitTemplateData(token, templateId));
              navigation.navigate("Checkout", {
                productId,
                templateId,
                username: userData.user_name,
              });
            } else {
              dispatch(
                notificationActions.toggleError("Username not available")
              );
              if (data.suggestions) {
                setSuggestions(data.suggestions);
              } else {
                setSuggestions([]);
              }
            }
          } else {
            dispatch(notificationActions.toggleError("invalid username"));
          }
        }}
      >
        <CustomText style={styles.buttonText}>Done</CustomText>
      </Pressable>
    </View>
  );
};

const mapStateToProps = (state) => ({
  token: state?.user?.token,
  baseUrl: state?.cms?.config["app.template.share_url.v2"],
  minLength: state?.cms?.config["app.config.feature.min_username_length"],
  maxLength: state?.cms?.config["app.config.feature.max_username_length"],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectUsername);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingBottom: 0,
    rowGap: 20,
  },

  numberContainer: {
    flexDirection: "row",
    width: "100%",
    gap: 28,
    justifyContent: "space-between",
  },
  upperContainer: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 16,
  },
  mainHeading: {
    color: "#DB5E71",
    fontSize: 32,
    fontWeight: 600,
  },
  secondaryHeading: {
    fontWeight: 300,
    color: "#8D8D8D",
    fontSize: 16,
  },
  middleContainer: {
    borderRadius: 20,
    alignItems: "flex-start",
    backgroundColor: "#DB5E71",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 16,
  },
  button: {
    backgroundColor: "#DB5E71",
    width: "90%",
    paddingVertical: 20,
    borderRadius: 20,
    paddingHorizontal: 20,
    position: "fixed",
    bottom: 15,
    maxWidth: 440,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
});
