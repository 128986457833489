import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
  FlatList,
  ActivityIndicator,
  Image,
  TextInput,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getReviews, getTemplateMesasgeData } from "../store/actions/cms";
import BackdropModal from "../components/BackdropModal";
import ImagePicker from "../components/ImagePicker";
import CustomText from "../components/CustomText";

const ChooseFileScreen = ({
  templateMessageData,
  route,
  token,
  loading,
  getData,
  navigation,
  templateId,
  categoryId,
}) => {
  const { key, onModalClose } = route.params;

  useEffect(() => {
    if (!categoryId || !templateId) {
      setTimeout(
        () =>
          navigation.replace("Homeroutes", {
            screen: "Dashboard",
          }),
        100
      );
    } else {
      if (!templateMessageData) getData(token);
    }
  }, [categoryId, templateMessageData]);

  const handleAdd = (data) => {
    onModalClose(data);
    navigation.goBack();
  };

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      <ImagePicker onModalClose={handleAdd} />
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>
          {key.replace("_", " ")}
        </CustomText>
      </View>
      <View style={styles.container}>
        <View style={{ flex: 1 }}>
          <FlatList
            data={templateMessageData?.filter(
              (item) => item.template_type === key
            )}
            numColumns={2}
            showsVerticalScrollIndicator={false}
            renderItem={(itemData) => {
              return (
                <Pressable
                  style={styles.categoryContainer}
                  onPress={() => handleAdd(itemData.item.data)}
                >
                  <Image
                    source={{ uri: itemData?.item?.data?.url }}
                    style={{
                      width: "100%",
                      aspectRatio: 1 / 1,
                      resizeMode: "contain",
                    }}
                  />
                </Pressable>
              );
            }}
            keyExtractor={(item) => {
              return item.data.title;
            }}
            alwaysBounceVertical={false}
          />
        </View>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  templateMessageData: state?.cms?.formMessageData,
  token: state?.user?.token,
  categoryId: state?.profile?.categoryId,
  templateId: state?.profile?.templateId,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getTemplateMesasgeData(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseFileScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
    marginTop: 16,
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#32505C",
    fontSize: 24,
    fontWeight: 400,
  },
  primaryHeading: {
    fontWeight: "bold",
    fontSize: 16,
  },
  categoryContainer: {
    flex: 1,
    margin: 16,
    padding: 12,
    borderRadius: 12,
    backgroundColor: "#ffffff",
    rowGap: 4,
    alignItems: "center",
    justifyContent: "center",
    ...Platform.select({
      web: {
        boxShadow: "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px",
      },
      android: { elevation: 10 },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
});
