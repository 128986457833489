import {
  ActivityIndicator,
  Image,
  Pressable,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Clipboard,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  AntDesign,
  Entypo,
  Feather,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import * as Linking from "expo-linking";

import {
  generatePdfLink,
  getShareMessage,
  getTemplateData,
  getTemplatePreData,
} from "../store/actions/cms";
import { notificationActions } from "../store/slices/notification";
import { generateShareContent } from "../utils/generateMessage";
import shareMessage from "../utils/shareMessage";
import CustomText from "../components/CustomText";
import WebView from "../components/WebView";
import BackdropModal from "../components/BackdropModal";
import { loginRequired } from "../utils/loginRequired";
import { API_BASE_URL } from "../config";

const OpeningModals = ({
  token,
  generatePdf,
  config,
  getTemplateData,
  selectedOrder,
  selectedOrderDetail,
  modalVisible,
  modalShareVisible,
  setModalVisible,
  setModalShareVisible,
}) => {
  const dispatch = useDispatch();

  const handleModalShareClose = () => {
    setModalShareVisible(false);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };
  const copyToClipboard = async (textToCopy) => {
    await Clipboard.setString(textToCopy);
    handleModalShareClose();
    dispatch(notificationActions.toggleError("Link copied"));
  };

  return (
    <>
      <BackdropModal
        visible={modalVisible}
        onClose={handleModalClose}
        Content={
          <View style={{ alignItems: "center" }}>
            <View
              style={[
                styles.shadow,
                {
                  alignItems: "center",
                  justifyContent: "center",
                  paddingHorizontal: "15%",
                  paddingVertical: 16,
                  rowGap: 8,
                  marginBottom: 16,
                  backgroundColor: "#FDEBE8",
                },
              ]}
            >
              <CustomText style={[{ color: "#DB5E71", fontSize: 28 }]}>
                Download
              </CustomText>
              <CustomText
                style={{ fontSize: 12, fontWeight: 300, textAlign: "center" }}
              >
                Share with your friends and family and enjoy the digital race
                with everyone
              </CustomText>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Pressable
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={async () => {
                    handleModalClose();
                    if (selectedOrder) {
                      const res = await generatePdf(selectedOrder);
                      if (res.status) {
                        Linking.openURL(res.url);
                      } else {
                        dispatch(
                          notificationActions.toggleError(
                            "Error generating Pdf"
                          )
                        );
                      }
                    }
                  }}
                >
                  <Image
                    source={{
                      uri: "https://bin-cdn-krn.s3.us-east-2.amazonaws.com/original/775da89a-1da0-4c41-a66e-5f9d29b6f694.png",
                    }}
                    style={{ aspectRatio: 1 / 1, width: 80 }}
                  />
                  <CustomText
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    Profile Pdf
                  </CustomText>
                </Pressable>
                <Pressable
                  onPress={async () => {
                    handleModalClose();
                    if (selectedOrder) {
                      console.log("chala naya wala");
                      const res = await generatePdf(
                        selectedOrder,
                        `${API_BASE_URL}api-public/html_viewer/${selectedOrderDetail?.items[0]?.product.template_html_id}?template_data_id=${selectedOrderDetail?.items[0]?.template_data_id}`,
                        {
                          vpWidth:
                            +config["app.service.template_card_image.width"],
                          vpHeight:
                            +config["app.service.template_card_image.height"],
                          template_type: "thumbnail",
                        }
                      );
                      if (res.status) {
                        Linking.openURL(res.url);
                      } else {
                        dispatch(
                          notificationActions.toggleError(
                            "Error generating Pdf"
                          )
                        );
                      }
                    }
                  }}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    source={{
                      uri: "https://bin-cdn-krn.s3.us-east-2.amazonaws.com/original/89b75915-d98f-47bb-a00e-f042f440c565.png",
                    }}
                    style={{ aspectRatio: 1 / 1, width: 80 }}
                  />
                  <CustomText
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    Card Image
                  </CustomText>
                </Pressable>
              </View>
            </View>

            <Pressable
              style={[styles.buttonBig, styles.shadow]}
              onPress={handleModalClose}
            >
              <CustomText style={styles.buttonBigText}>Cancel</CustomText>
            </Pressable>
          </View>
        }
      />
      <BackdropModal
        visible={modalShareVisible}
        onClose={handleModalShareClose}
        Content={
          <View style={{ alignItems: "center" }}>
            <View
              style={[
                styles.shadow,
                {
                  alignItems: "center",
                  justifyContent: "center",
                  paddingHorizontal: "10%",
                  paddingVertical: 32,
                  rowGap: 32,
                  backgroundColor: "#FDEBE8",
                },
              ]}
            >
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomText
                  style={[
                    styles.mainHeading,
                    { color: "#DB5E71", marignLeft: "20%" },
                  ]}
                >
                  Share With
                </CustomText>
                <Pressable
                  style={[
                    styles.button,
                    {
                      aspectRatio: 1 / 1,
                      bottom: 0,
                      right: 0,
                      borderRadius: "50%",
                    },
                  ]}
                  onPress={() => {
                    handleModalShareClose();
                  }}
                >
                  <Entypo name="cross" size={24} color="white" />
                </Pressable>
              </View>
              {/* Link container */}

              <View
                style={[
                  styles.shadow,
                  {
                    Width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 12,
                    borderRadius: 12,
                    backgroundColor: "#FFFFFF",
                    justifyContent: "space-between",
                  },
                ]}
              >
                <CustomText
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={{
                    fontWeight: 400,
                    color: "#858490",
                    fontSize: 20,
                  }}
                >
                  {`${config["app.template.share_url.v2"]}${
                    !!selectedOrderDetail["items"] &&
                    selectedOrderDetail["items"][0]["username"]
                    // .toString()
                    // .replace("order_", "")
                    // .toString()
                    // .replace("pay_", "")
                  }
                    `}
                </CustomText>
              </View>

              {/* Copy link button */}
              <Pressable
                onPress={() =>
                  copyToClipboard(`${config["app.template.share_url.v2"]}${
                    !!selectedOrderDetail["items"] &&
                    selectedOrderDetail["items"][0]["username"]
                    // .toString()
                    // .replace("order_", "")
                    // .toString()
                    // .replace("pay_", "")
                  }
                  `)
                }
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 12,
                    borderRadius: 28,
                    columnGap: 8,
                    backgroundColor: "#FFFFFF",
                    justifyContent: "space-between",
                    border: "1px solid #6B6B6B",
                  },
                ]}
              >
                <Feather name="paperclip" size={14} color="#6B6B6B" />
                <CustomText
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={{
                    fontWeight: 400,
                    color: "#6B6B6B",
                    fontSize: 14,
                  }}
                >
                  Copy Link
                </CustomText>
              </Pressable>

              <CustomText
                style={{ fontSize: 12, fontWeight: 300, textAlign: "center" }}
              >
                Share with your friends and family and enjoy the digital race
                with everyone
              </CustomText>

              <Pressable
                onPress={async () => {
                  const tempData = await getTemplateData(token, {
                    template_data_id:
                      selectedOrderDetail.items[0]?.template_data_id,
                    order_id: selectedOrderDetail.order_id,
                    type: "SHARE_TEMPLATE_MESSAGE",
                  });
                  const formattedRessponse = tempData?.data?.replace(
                    /\\n/g,
                    "\n"
                  );
                  if (tempData && formattedRessponse) {
                    Linking.openURL(
                      `https://wa.me?text=${encodeURIComponent(
                        formattedRessponse
                      )}`
                    );
                  }
                }}
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 12,
                    borderRadius: 28,
                    columnGap: 8,

                    backgroundColor: "#FFFFFF",
                    justifyContent: "space-between",
                    border: "1px solid #1D8F31",
                  },
                ]}
              >
                <Image
                  source={{
                    uri: "https://bin-cdn-krn.s3.us-east-2.amazonaws.com/original/06e57208-12be-4113-b335-0ea0fbd31177.png",
                  }}
                  style={{ width: 20, aspectRatio: 1 / 1 }}
                />
                <CustomText
                  style={{
                    fontWeight: 400,
                    color: "#1D8F31",
                    fontSize: 14,
                  }}
                >
                  Share on Whatsapp
                </CustomText>
              </Pressable>

              <Pressable
                onPress={async () => {
                  const tempData = await getTemplateData(token, {
                    template_data_id:
                      selectedOrderDetail.items[0]?.template_data_id,
                    order_id: selectedOrderDetail.order_id,
                    type: "SHARE_TEMPLATE_MESSAGE",
                  });
                  const formattedRessponse = tempData?.data?.replace(
                    /\\n/g,
                    "\n"
                  );
                  if (tempData && formattedRessponse)
                    shareMessage(formattedRessponse);
                }}
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 12,
                    columnGap: 8,
                    borderRadius: 28,
                    backgroundColor: "#FFFFFF",
                    justifyContent: "space-between",
                    border: "1px solid #EB996E",
                  },
                ]}
              >
                <Ionicons name="share-social-sharp" size={14} color="#EB996E" />
                <CustomText
                  style={{
                    fontWeight: 400,
                    color: "#EB996E",
                    fontSize: 14,
                  }}
                >
                  Share on others
                </CustomText>
              </Pressable>
            </View>
          </View>
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  config: state?.cms?.config,
  token: state?.user?.token,
  loading: state?.notification?.loadingSpinner,
  selectedOrder: state?.notification?.selectedOrder,
  selectedOrderDetail: state?.notification?.selectedOrderDetail,
  modalVisible: state?.notification?.modalVisible,
  modalShareVisible: state?.notification?.modalShareVisible,
});

const mapDispatchToProps = (dispatch) => ({
  generatePdf: (data, url, extra) =>
    dispatch(generatePdfLink(data, url, extra)),
  getTemplateData: (token, id) => dispatch(getShareMessage(token, id)),
  setModalVisible: (data) =>
    dispatch(notificationActions.setModalVisible(data)),
  setModalShareVisible: (data) =>
    dispatch(notificationActions.setModalShareVisible(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpeningModals);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 12,
  },
  flexContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: 28,
  },
  upperContainer: {
    padding: 8,
    alignItems: "start",
    justifyContent: "center",
    paddingLeft: 20,
  },
  mainHeading: {
    color: "#32505C",
    fontSize: 28,
  },
  middleContainer: {
    flexDirection: "row",
    width: "100%",
    columnGap: 16,
    marginBottom: 16,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 16,
  },
  button: {
    backgroundColor: "#DB5E71",
    padding: 8,
    borderRadius: 8,
    position: "absolute",
    bottom: 8,
    right: 8,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
  buttonBig: {
    backgroundColor: "#DB5E71",
    paddingVertical: 20,

    paddingHorizontal: 20,
    maxWidth: 440,
    alignItems: "center",
  },
  buttonBigText: {
    color: "white",
    fontSize: 20,
  },
  shadow: {
    width: "90%",
    borderRadius: 20,
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
});
